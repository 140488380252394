import React, { useState , useEffect } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateField } from '../InscriptionSlice';
import ClientSign from '../apisigner/ClientSign';

const Inscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inscriptionData = useSelector((state) => state.inscription);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSigningContract, setIsSigningContract] = useState(false);
  const [acceptContract, setAcceptContract] = useState(false);
  const role = inscriptionData.role || "AUTEUR"; // Valeur par défaut "AUTEUR"


  const initiateContractSigning = async () => {
    setIsSigningContract(true);
    try {
      const client = new ClientSign();

      // Signature Request
      const signatureRequest = await client.initiateSignatureRequest();
      const document = await client.uploadDocument(signatureRequest.id);
      const signer = await client.addSigner(signatureRequest.id, document.id, {
        email: inscriptionData.email,
      });

      console.log('Signer:', signer);
      await client.activateSignatureRequest(signatureRequest.id);

      setAcceptContract(true);
    } catch (error) {
      console.error('Error initiating contract signing:', error);
      setMissingFields([
        { name: 'contract', label: 'Erreur lors de la signature du contrat. Veuillez réessayer.' },
      ]);
      setIsModalOpen(true);
    } finally {
      setIsSigningContract(false);
    }
  };

  useEffect(() => {
    if (!inscriptionData.role) {
      dispatch(updateField({ field: 'role', value: 'AUTEUR' }));
    }
  }, [dispatch, inscriptionData.role]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateField({ field: name, value }));
  };

  const validateFields = () => {
    const requiredFields = [
      { name: 'username', label: 'Nom' },
      { name: 'prenom', label: 'Prénom' },
      { name: 'ville', label: 'Ville' },
      { name: 'email', label: 'Email' },
      { name: 'identifiant', label: 'Identifiant' },
      { name: 'password', label: 'Mot de passe' },
      { name: 'password_confirmation', label: 'Confirmation du mot de passe' },
      { name: 'role', label: 'Rôle' },
    ];

    const missing = requiredFields.filter((field) => !inscriptionData[field.name]);

    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if (
      inscriptionData.password &&
      !passwordRegex.test(inscriptionData.password)
    ) {
      missing.push({
        name: 'password',
        label:
          'Mot de passe (8 caractères minimum avec au moins une majuscule et un chiffre)',
      });
    }

    if (
      inscriptionData.password &&
      inscriptionData.password_confirmation &&
      inscriptionData.password !== inscriptionData.password_confirmation
    ) {
      missing.push({
        name: 'password_confirmation',
        label: 'Les mots de passe ne correspondent pas',
      });
    }

    return missing;
  };

  const handleSuivantClick = () => {
    const missing = validateFields();
    if (missing.length > 0) {
      setMissingFields(missing);
      setIsModalOpen(true);
    } else {
      navigate('/inscriptionsuite');
    }
  };

  
  return (
    <div className="bg-white py-10 px-4 md:px-0 md:max-w-4xl mx-auto">
      <h1 className="text-center text-3xl font-bold mb-8">INSCRIPTION</h1>

      <div className="space-y-6">
        <input
          type="text"
          name="username"
          placeholder="VOTRE NOM"
          value={inscriptionData.username}
          onChange={handleInputChange}
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />
        <input
          type="text"
          name="prenom"
          placeholder="VOTRE PRÉNOM"
          value={inscriptionData.prenom}
          onChange={handleInputChange}
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />
       
        <input
          type="text"
          name="country"
          placeholder="VOTRE PAYS"
          value={inscriptionData.country}
          onChange={handleInputChange}
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />
         <input
          type="text"
          name="ville"
          placeholder="VOTRE VILLE"
          value={inscriptionData.ville}
          onChange={handleInputChange}
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />
        <input
          type="email"
          name="email"
          placeholder="VOTRE EMAIL"
          value={inscriptionData.email}
          onChange={handleInputChange}
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />
        <input
          type="text"
          name="identifiant"
          placeholder="CRÉEZ UN IDENTIFIANT"
          value={inscriptionData.identifiant}
          onChange={handleInputChange}
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />

        <div className="relative">
          <input
            type={showPassword ? 'text' : 'password'}
            name="password"
            placeholder="CRÉEZ UN MOT DE PASSE"
            value={inscriptionData.password}
            onChange={handleInputChange}
            className="w-full border border-gray-400 p-4 rounded-lg italic"
          />
          <p className="absolute right-28 top-4 text-sm text-red-600">
            (8 caractères minimum avec au moins une majuscule et un chiffre)
          </p>
          <span
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-4 top-4 cursor-pointer"
          >
            {showPassword ? '👁️' : '👁️‍🗨️'}
          </span>
        </div>

        <div className="relative">
          <input
            type={showConfirmPassword ? 'text' : 'password'}
            name="password_confirmation"
            placeholder="CONFIRMER VOTRE MOT DE PASSE"
            value={inscriptionData.password_confirmation || ''}
            onChange={handleInputChange}
            className="w-full border border-gray-400 p-4 rounded-lg italic"
          />
          <span
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            className="absolute right-4 top-4 cursor-pointer"
          >
            {showConfirmPassword ? '👁️' : '👁️‍🗨️'}
          </span>
        </div>

        <div className="relative flex items-center space-x-4">
          <label className="font-semibold italic text-lg w-[30rem]">
            VOUS VOUS INSCRIVEZ EN TANT QUE :
          </label>
          <div className="relative w-4/5">
  {/* <select
    name="role"
    value={inscriptionData.role}
    onChange={handleInputChange}
    className="appearance-none border border-gray-400 p-4 rounded-lg italic w-full"
  >
    <option value="AUTEUR">AUTEUR</option>
    <option value="DIFFUSEUR">DIFFUSEUR</option>
    <option value="AUTEUR-DIFFUSEUR">AUTEUR-DIFFUSEUR</option>
    <option value="GRAPHISTES">GRAPHISTES</option>
    <option value="CORRECTEURS">CORRECTEURS</option>
    <option value="ILLUSTRATEURS">ILLUSTRATEURS</option>
    <option value="SCENARISTES">SCENARISTES</option>
    <option value="COLORISTES">COLORISTES</option>
    <option value="TRADUCTEURS">TRADUCTEURS</option>
    <option value="CHARGES_D_EDITION">CHARGÉS D'ÉDITION</option>
    <option value="CHARGES_DE_RELATION_PRESSE">CHARGÉS DE RELATION PRESSE</option>
    <option value="BETA-LECTEURS">BÊTA-LECTEURS</option>
  </select> */}
  <select
  name="role"
  value={inscriptionData.role || "AUTEUR"} // Valeur par défaut si Redux ne la fournit pas encore
  onChange={handleInputChange}
  className="appearance-none border border-gray-400 p-4 rounded-lg italic w-full"
>
  <option value="AUTEUR">AUTEUR</option>
  <option value="DIFFUSEUR">DIFFUSEUR</option>
  <option value="AUTEUR-DIFFUSEUR">AUTEUR-DIFFUSEUR</option>
  <option value="GRAPHISTES">GRAPHISTES</option>
  <option value="CORRECTEURS">CORRECTEURS</option>
  <option value="ILLUSTRATEURS">ILLUSTRATEURS</option>
  <option value="SCENARISTES">SCENARISTES</option>
  <option value="COLORISTES">COLORISTES</option>
  <option value="TRADUCTEURS">TRADUCTEURS</option>
  <option value="CHARGES_D_EDITION">CHARGÉS D'ÉDITION</option>
  <option value="CHARGES_DE_RELATION_PRESSE">CHARGÉS DE RELATION PRESSE</option>
  <option value="BETA-LECTEURS">BÊTA-LECTEURS</option>
</select>

  <div className="absolute inset-y-0 right-4 flex items-center pointer-events-none">
    <IoMdArrowDropdown className="w-12 h-12 text-green-500" />
  </div>
</div>
        </div>

        {/* Conditions générales d'utilisation */}
        <label className="block flex items-center">
          <input type="checkbox" className="mr-2 w-6 h-6" />
          <span className="italic font-semibold">
            OUI, j'accepte les{' '}
            <a
              href="/pdf/Conditions générales EDITIONS 7 V2.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              conditions générales d'utilisation de la plateforme
            </a>
          </span>
        </label>

        {/* Politique de confidentialité uniquement pour AUTEUR */}
        {/* {(inscriptionData.role === 'AUTEUR' || inscriptionData.role === 'AUTEUR-DIFFUSEUR') && (
          <label className="block flex items-center">
            <input type="checkbox" className="mr-2 w-6 h-6" />
            <span className="italic font-semibold">
              OUI, j'accepte la{' '}
              <a href="/pdf/CGVP auteur V2.pdf"
               target="_blank"
               rel="noopener noreferrer"
                className="text-blue-600 hover:underline">
                Conditions Générales de Vente Particulières aux Auteurs
              </a>
            </span>
          </label>
        )} */}
        {/* Politique de confidentialité uniquement pour AUTEUR */}
{(role === 'AUTEUR' || role === 'AUTEUR-DIFFUSEUR') && (
  <label className="block flex items-center">
    <input type="checkbox" className="mr-2 w-6 h-6" />
    <span className="italic font-semibold">
      OUI, j'accepte la{' '}
      <a href="/pdf/CGVP auteur V2.pdf"
         target="_blank"
         rel="noopener noreferrer"
         className="text-blue-600 hover:underline">
        Conditions Générales de Vente Particulières aux Auteurs
      </a>
    </span>
  </label>
)}

{/* Contrat d'édition uniquement pour DIFFUSEUR */}
{/* {(role === 'DIFFUSEUR' || role === 'AUTEUR-DIFFUSEUR') && (
  <label className="block flex items-center">
    <input
      type="checkbox"
      className="mr-2 w-6 h-6"
      checked={acceptContract}
      onChange={(e) => {
        if (!acceptContract) {
          initiateContractSigning();
        }
      }}
      disabled={isSigningContract}
    />
    <span className="italic font-semibold">
      OUI, j'accepte de{' '}
      <a
        href="#"
        className="text-blue-600 hover:underline"
        onClick={(e) => {
          e.preventDefault();
          initiateContractSigning();
        }}
      >
        signer électroniquement le contrat de partenariat pour être diffuseur 
      </a>
      {isSigningContract && (
        <span className="ml-2 text-blue-600">(Envoi en cours...)</span>
      )}
    </span>
  </label>
)} */}


        {/* Contrat d'édition uniquement pour DIFFUSEUR */}
        {(inscriptionData.role === 'DIFFUSEUR' || inscriptionData.role === 'AUTEUR-DIFFUSEUR') && (
  <label className="block flex items-center">
    <input
      type="checkbox"
      className="mr-2 w-6 h-6"
        />
    <span className="italic font-semibold">
      OUI, j'accepte {' '}

      <a href="pdf/Contrat-EDITIONS7-DIFFUSEUR-V2.pdf"
         target="_blank"
         rel="noopener noreferrer"
         className="text-blue-600 hover:underline">
         le contrat de partenariat pour être diffuseur 
      </a>
      {isSigningContract && (
        <span className="ml-2 text-blue-600">(Envoi en cours...)</span>
      )}
    </span>
  </label>
)}


        <div className="flex justify-between items-center mt-6">
          <p className="text-lg font-bold">
            L'abonnement à la plateforme est à 29  €.
          </p>
          <button
            onClick={handleSuivantClick}
            className="bg-green-600 text-white font-bold py-2 px-8 rounded-lg hover:bg-green-500 text-2xl"
          >
            SUIVANT
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center w-96">
            <h2 className="text-2xl font-bold mb-4">Champs manquants</h2>
            <ul className="text-left text-red-600">
              {missingFields.map((field, index) => (
                <li key={index}>{field.label}</li>
              ))}
            </ul>
            <button
              className="bg-red-600 text-white font-bold py-2 px-8 rounded-lg hover:bg-red-500 mt-4"
              onClick={() => setIsModalOpen(false)}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Inscription;
