import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateField, resetFields } from '../InscriptionSlice'; 
import { useNavigate } from 'react-router-dom';
import stripe from '../img/logo-stripe.png';
import { IoMdEye } from 'react-icons/io'; // Eye icon for show
import { loadStripe } from '@stripe/stripe-js';
import BASE_URL from '../key';

// Initialize Stripe with your publishable key
// const stripePromise = loadStripe('pk_test_51OAaGgFC5nnuMcZVv5nnEc5wLlTVUl17HDRtP1u0TqED8hCfXldG24pKBxOBiA00kpK0hWMQDQpaKyT5rrWjYHwG00YtG4cVHs'); // Replace with your actual Stripe publishable key
const stripePromise = loadStripe('pk_live_51OAaGgFC5nnuMcZVBSWutnRASba3jzLxt52lYVmGstVtkexJ1nIokT4kTCQMxowDG6bdP82Y9jrmHbn3Z4GM6rtE00y2IQwjvo'); // Replace with your actual Stripe publishable key

const InscriptionSuite = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inscriptionData = useSelector((state) => state.inscription);

  const [fileName, setFileName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false); // État pour afficher/masquer le modal
  const [stripeError, setStripeError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [elements, setElements] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [isPhotoErrorModalOpen, setIsPhotoErrorModalOpen] = useState(false);


  useEffect(() => {
    // Initialize Stripe elements
    const setupStripe = async () => {
      const stripe = await stripePromise;
      const elements = stripe.elements();
      const card = elements.create('card');
      card.mount('#card-element');
      setElements(elements);
    };
    
    setupStripe();
  }, []);

  // Gestion des changements dans les champs de texte
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateField({ field: name, value }));
  };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setFileName(file.name);
  //     dispatch(updateField({ field: 'photo_profil', value: file }));
  //     setProfilePhoto(file);
  //     setFormErrors((prevErrors) => ({ ...prevErrors, profilePhoto: "" }));
      
  //   }
  // };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      dispatch(updateField({ field: "photo_profil", value: file }));
      setProfilePhoto(file);
      setFormErrors((prevErrors) => ({ ...prevErrors, profilePhoto: "" }));
    }
  };

  const handleSubmit = async () => {
    setStripeError(null);

    const formData = new FormData();
    if (!profilePhoto) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        profilePhoto: "La photo de profil est obligatoire.",
      }));
      setIsPhotoErrorModalOpen(true); // Open the error modal
      return;
    }
    Object.entries(inscriptionData).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value);
      }
    });

    try {
      // First, create a PaymentIntent on the backend
      const paymentIntentResponse = await fetch(`${BASE_URL}/create-payment-intent`, {
            // const paymentIntentResponse = await fetch('https://editions7.com/admin/public/api/create-payment-intent', {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`, // Add token if required
      },
        body: JSON.stringify({
          email: inscriptionData.email,
          username: inscriptionData.username,
        }),
      });

      if (!paymentIntentResponse.ok) {
        throw new Error('Failed to create payment intent');
      }

      const { clientSecret } = await paymentIntentResponse.json();
      console.log('Payment Intent created');

      // Confirm the payment with Stripe
      const stripe = await stripePromise;
      const { error: paymentError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement('card'),
          billing_details: {
            name: inscriptionData.username,
            email: inscriptionData.email,
          },
        },
      });

      if (paymentError) {
        throw new Error(paymentError.message);
      }

      console.log('Payment status:', paymentIntent.status);

      if (paymentIntent.status === 'succeeded') {
        console.log('Payment successful!');
        
        // Add payment info to formData
        formData.append('payment_id', paymentIntent.id);
        formData.append('payment_status', 'succeeded');
        formData.append('abonnement_status', 'paye');

        // Register user
        const response = await fetch(`${BASE_URL}/register`, {
        // const response = await fetch('https://editions7.com/admin/public/api/register', {
          headers: {
            'Accept': 'application/json', // Facultatif, pour accepter JSON en réponse
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const userData = await response.json();
          console.log('Nouvel utilisateur inscrit avec succès:', userData);
        
          // 🔥 Supprimer les anciens tokens et rôles
          localStorage.removeItem('token');
          localStorage.removeItem('isLoggedIn');
          localStorage.removeItem('userRole');
        
          // 🔥 Sauvegarder le nouveau token et rôle
          if (userData.token) {
            localStorage.setItem('token', userData.token);
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('userRole', inscriptionData.role);
        
            // ⚡ Forcer la mise à jour du Header
            window.dispatchEvent(new Event("storage"));
          } else {
            console.error("Aucun token retourné après l'inscription !");
          }
        
          // Redirection vers la page du compte selon le rôle
          switch (inscriptionData.role) {
            case 'AUTEUR':
              navigate('/mon-compte');
              break;
            case 'DIFFUSEUR':
              navigate('/compte-diffuseur');
              break;
            case 'AUTEUR-DIFFUSEUR':
              navigate('/compte-auteur-diffuseur');
              break;
            default:
              navigate('/compte-autres');
              break;
          }
        }
        else {
          const error = await response.json();
          console.error('Registration error:', error);
          throw new Error(JSON.stringify(error.errors));
        }
      } else {
        throw new Error(`Payment failed with status: ${paymentIntent.status}`);
      }
    } catch (err) {
      console.error('Error:', err);
      setStripeError(err.message);
      formData.append('abonnement_status', 'non_paye');
      alert(`Une erreur est survenue: ${err.message}`);
    } finally {
      setIsProcessing(false);
    }
  };
  

  const closePhotoErrorModal = () => {
    setIsPhotoErrorModalOpen(false);
  };

  const handleCloseModal = () => {
    dispatch(resetFields());
    setIsModalOpen(false); 
    navigate(-1); 
  };

  return (
    <div className="bg-white py-10 px-4 md:px-0 md:max-w-4xl mx-auto">
      <h1 className="text-center text-3xl font-bold mb-8">Complétez votre inscription</h1>

      <div className="space-y-6">
        {/* Photo de profil */}
        {/* <div className="flex items-center space-x-4">
          <label className="text-lg font-bold">VOTRE PHOTO DE PROFIL :</label>
          <input
            type="file"
            id="fileInput"
            className="hidden"
            onChange={handleFileChange}
          />
          <button
            className="bg-[#38dcaa] text-white py-2 px-4 hover:bg-[#38dcaa] cursor-pointer"
            onClick={() => document.getElementById('fileInput').click()}
          >
            Choisissez un fichier
          </button>
          {fileName && <span className="text-gray-700 italic">{fileName}</span>}
        </div> */}
        {/* Photo de profil */}
       <div className="flex flex-col">
          <div className="flex items-center space-x-4">
          <label className="text-lg font-bold">
      VOTRE PHOTO DE PROFIL : <span className="text-red-500">*</span>
    </label>            <input
              type="file"
              id="fileInput"
              className="hidden"
              onChange={handleFileChange}
            />
            <button
              type="button"
              className="bg-[#38dcaa] text-white py-2 px-4 hover:bg-[#38dcaa] cursor-pointer"
              onClick={() => document.getElementById("fileInput").click()}
            >
              Choisissez un fichier
            </button>
            {fileName && <span className="text-gray-700 italic">{fileName}</span>}
          </div>
          {formErrors.profilePhoto && (
            <span className="text-red-500 text-sm">{formErrors.profilePhoto}</span>
          )}
        </div>

        {/* Adresse de facturation */}
        <input
          type="text"
          name="adresse_facturation"
          placeholder="VOTRE ADRESSE DE FACTURATION"
          value={inscriptionData.adresse_facturation || ''}
          onChange={handleInputChange}
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />

        {/* Commune */}
        <input
          type="text"
          name="commune"
          placeholder="COMMUNE"
          value={inscriptionData.commune || ''}
          onChange={handleInputChange}
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />

        {/* Code postal */}
        <input
          type="text"
          name="code_postal"
          placeholder="CODE POSTAL"
          value={inscriptionData.code_postal || ''}
          onChange={handleInputChange}
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />

        {/* Téléphone */}
        <input
          type="text"
          name="telephone"
          placeholder="TELEPHONE"
          value={inscriptionData.telephone || ''}
          onChange={handleInputChange}
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />

        {/* Site internet */}
        <input
          type="text"
          name="site_internet"
          placeholder="SITE INTERNET"
          value={inscriptionData.site_internet || ''}
          onChange={handleInputChange}
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />

        {/* Réglez votre adhésion */}
        <div className="mb-8">
          <p className="font-bold mb-4">REGLEZ VOTRE ADHESION :</p>
          <p className="italic text-sm font-semibold mb-4">
            L'abonnement à la plateforme est à 29 € 
          </p>
          <div id="card-element" className="border border-gray-300 p-4 rounded-lg mb-4">
            {/* Stripe Card Element will be mounted here */}
          </div>
          {stripeError && (
            <p className="text-red-500 text-sm mt-2">{stripeError}</p>
          )}
          <img
            src={stripe}
            alt="Stripe Payment"
            className="w-1/3 mx-auto mt-4"
          />
        </div>

        {/* Boutons */}
        <div className="flex justify-between mt-8">
          <button
            className="bg-red-600 text-white font-bold py-4 px-12 rounded-lg hover:bg-red-500"
            onClick={() => navigate(-1)}
            disabled={isProcessing}
          >
            RETOUR
          </button>
          <button
            className={`bg-green-600 text-white font-bold py-4 px-12 rounded-lg ${
              isProcessing ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-500'
            }`}
            onClick={handleSubmit}
            disabled={isProcessing}
          >
            {isProcessing ? 'TRAITEMENT...' : "S'INSCRIRE"}
          </button>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center w-96">
            <h2 className="text-2xl font-bold mb-4">Inscription réussie !</h2>
            <p className="text-lg mb-6">
              Votre inscription a été enregistrée avec succès.
            </p>
            <button
              className="bg-green-600 text-white font-bold py-2 px-8 rounded-lg hover:bg-green-500"
              onClick={handleCloseModal}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InscriptionSuite;
