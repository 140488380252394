
import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import Header1 from "./diffuseurs/Header1"; 
import { IoMdArrowDropright } from "react-icons/io"; 
import BASE_URL from '../key';
import Sidebar from '../components/sidebarAuteur';




const mapContainerStyle = {
  width: "100%",
  height: "500px",
};

const center = {
  lat: 46.603354, // Center of France
  lng: 1.888334,
};

const departements = [
  { code: "01", name: "Ain" },
  { code: "02", name: "Aisne" },
  { code: "03", name: "Allier" },
  { code: "04", name: "Alpes-de-Haute-Provence" },
  { code: "05", name: "Hautes-Alpes" },
  { code: "06", name: "Alpes-Maritimes" },
  { code: "07", name: "Ardèche" },
  { code: "08", name: "Ardennes" },
  { code: "09", name: "Ariège" },
  { code: "10", name: "Aube" },
  { code: "11", name: "Aude" },
  { code: "12", name: "Aveyron" },
  { code: "13", name: "Bouches-du-Rhône" },
  { code: "14", name: "Calvados" },
  { code: "15", name: "Cantal" },
  { code: "16", name: "Charente" },
  { code: "17", name: "Charente-Maritime" },
  { code: "18", name: "Cher" },
  { code: "19", name: "Corrèze" },
  { code: "2A", name: "Corse-du-Sud" },
  { code: "2B", name: "Haute-Corse" },
  { code: "21", name: "Côte-d'Or" },
  { code: "22", name: "Côtes-d'Armor" },
  { code: "23", name: "Creuse" },
  { code: "24", name: "Dordogne" },
  { code: "25", name: "Doubs" },
  { code: "26", name: "Drôme" },
  { code: "27", name: "Eure" },
  { code: "28", name: "Eure-et-Loir" },
  { code: "29", name: "Finistère" },
  { code: "30", name: "Gard" },
  { code: "31", name: "Haute-Garonne" },
  { code: "32", name: "Gers" },
  { code: "33", name: "Gironde" },
  { code: "34", name: "Hérault" },
  { code: "35", name: "Ille-et-Vilaine" },
  { code: "36", name: "Indre" },
  { code: "37", name: "Indre-et-Loire" },
  { code: "38", name: "Isère" },
  { code: "39", name: "Jura" },
  { code: "40", name: "Landes" },
  { code: "41", name: "Loir-et-Cher" },
  { code: "42", name: "Loire" },
  { code: "43", name: "Haute-Loire" },
  { code: "44", name: "Loire-Atlantique" },
  { code: "45", name: "Loiret" },
  { code: "46", name: "Lot" },
  { code: "47", name: "Lot-et-Garonne" },
  { code: "48", name: "Lozère" },
  { code: "49", name: "Maine-et-Loire" },
  { code: "50", name: "Manche" },
  { code: "51", name: "Marne" },
  { code: "52", name: "Haute-Marne" },
  { code: "53", name: "Mayenne" },
  { code: "54", name: "Meurthe-et-Moselle" },
  { code: "55", name: "Meuse" },
  { code: "56", name: "Morbihan" },
  { code: "57", name: "Moselle" },
  { code: "58", name: "Nièvre" },
  { code: "59", name: "Nord" },
  { code: "60", name: "Oise" },
  { code: "61", name: "Orne" },
  { code: "62", name: "Pas-de-Calais" },
  { code: "63", name: "Puy-de-Dôme" },
  { code: "64", name: "Pyrénées-Atlantiques" },
  { code: "65", name: "Hautes-Pyrénées" },
  { code: "66", name: "Pyrénées-Orientales" },
  { code: "67", name: "Bas-Rhin" },
  { code: "68", name: "Haut-Rhin" },
  { code: "69", name: "Rhône" },
  { code: "70", name: "Haute-Saône" },
  { code: "71", name: "Saône-et-Loire" },
  { code: "72", name: "Sarthe" },
  { code: "73", name: "Savoie" },
  { code: "74", name: "Haute-Savoie" },
  { code: "75", name: "Paris" },
  { code: "76", name: "Seine-Maritime" },
  { code: "77", name: "Seine-et-Marne" },
  { code: "78", name: "Yvelines" },
  { code: "79", name: "Deux-Sèvres" },
  { code: "80", name: "Somme" },
  { code: "81", name: "Tarn" },
  { code: "82", name: "Tarn-et-Garonne" },
  { code: "83", name: "Var" },
  { code: "84", name: "Vaucluse" },
  { code: "85", name: "Vendée" },
  { code: "86", name: "Vienne" },
  { code: "87", name: "Haute-Vienne" },
  { code: "88", name: "Vosges" },
  { code: "89", name: "Yonne" },
  { code: "90", name: "Territoire de Belfort" },
  { code: "91", name: "Essonne" },
  { code: "92", name: "Hauts-de-Seine" },
  { code: "93", name: "Seine-Saint-Denis" },
  { code: "94", name: "Val-de-Marne" },
  { code: "95", name: "Val-d'Oise" }
];



const TrouverDesDiffuseurs = () => {
  const [departement, setDepartement] = useState("");
  const [diffuseurs, setDiffuseurs] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [selectedDiffuseur, setSelectedDiffuseur] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDiffuseur, setSelectedDiffuseur] = useState(null);
const [selectedModalType, setSelectedModalType] = useState(""); // "submit" ou "contact"

  const [error, setError] = useState(''); 
  const [livres, setLivres] = useState([]); 
  const [selectedLivre, setSelectedLivre] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false); // Nouvel état
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [newMessage, setNewMessage] = useState('');
  const [isLivreEnvoyeModalOpen, setIsLivreEnvoyeModalOpen] = useState(false);


  const [selectedProfession, setSelectedProfession] = useState("");

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false); // État pour le modal de succès
  const [successMessage, setSuccessMessage] = useState(""); 
  const [loadingCities, setLoadingCities] = useState(false);
  const [cities, setCities] = useState([]); // Ajout de l'état pour stocker les villes


  const fetchLivresAuteurs = async () => {
    setError('');
    try {
        // const response = await fetch('http://localhost:8000/api/livres-Auteur', {
            const response = await fetch(`${BASE_URL}/livres-Auteur`, {

            method: 'GET',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Erreur lors de la récupération des livres.');
        }

        const data = await response.json();
        setLivres(data);
    } catch (error) {
        setError(error.message);
    }
};

const countries = ['France', 'Belgique', 'Luxembourg', 'Suisse'];
// const cities = ['Paris', 'Lyon', 'Marseille'];

useEffect(() => {
  fetchLivresAuteurs();
}, []);

const handleModalOpen = () => {
  setIsModalOpen(true);
};

const handleModalClose = () => {
  setSelectedModalType(null);
  setSelectedDiffuseur(null);
};


// const handleSubmit = async (e) => {
//   e.preventDefault();

//   if (!selectedLivre || !selectedDiffuseur) {
//     alert("Veuillez sélectionner un livre et un diffuseur.");
//     return;
//   }

//   const pitch = document.querySelector("textarea[name='pitch']").value;
//   const resume = document.querySelector("textarea[name='resume']").value;
//   const quantite_envoyee = document.querySelector("textarea[name='quantite_envoyee']").value;


//   try {
//     // const response = await fetch("http://localhost:8000/api/livres/assign", {
//         const response = await fetch(`${BASE_URL}/livres/assign`, {

//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         livre_id: selectedLivre.id,
//         diffuseur_id: selectedDiffuseur.id,
//         pitch: pitch,
//         resume: resume,
//         quantite_envoyee: quantite_envoyee,
//       }),
//     });

//     if (!response.ok) {
//       throw new Error("Une erreur est survenue lors de l'envoi du livre.");
//     }

//     const data = await response.json();
//     setIsModalOpen(false); // Ferme le modal principal
//     setIsSuccess(true); // Affiche le modal de succès
//   } catch (error) {
//     console.error("Erreur lors de l'envoi du livre :", error);
//     alert("Une erreur est survenue. Veuillez réessayer.");
//   }
// };

const handleSubmit = async (e) => {
  e.preventDefault();

  if (!selectedLivre || !selectedDiffuseur) {
    alert("Veuillez sélectionner un livre et un diffuseur.");
    return;
  }

  const pitch = document.querySelector("textarea[name='pitch']").value;
  const resume = document.querySelector("textarea[name='resume']").value;
  const quantite_envoyee = document.querySelector("textarea[name='quantite_envoyee']").value;

  try {
    const response = await fetch(`${BASE_URL}/livres/assign`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        livre_id: selectedLivre.id,
        diffuseur_id: selectedDiffuseur.id,
        pitch: pitch,
        resume: resume,
        quantite_envoyee: quantite_envoyee,
      }),
    });

    if (!response.ok) {
      throw new Error("Une erreur est survenue lors de l'envoi du livre.");
    }

    // Ferme le modal principal et ouvre le modal de succès
    setSelectedDiffuseur(null); // Ferme le modal de soumission
    setIsLivreEnvoyeModalOpen(true); // Affiche le modal de confirmation

  } catch (error) {
    console.error("Erreur lors de l'envoi du livre :", error);
    alert("Une erreur est survenue. Veuillez réessayer.");
  }
};




  const getCoordinatesFromAddress = async (address) => {
    const apiKey = "AIzaSyBbGmpRZdudt9VOiFSVJRTGEKjV-XG2h6o";
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        return { lat, lng };
      } else {
        console.error(`Geocoding error: ${data.status}`);
        return null;
      }
    } catch (error) {
      console.error("Geocoding API request error:", error);
      return null;
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    setError("");
  
    console.log("🔍 Paramètres envoyés :", {
      country: selectedCountry,
      commune: selectedCity,
      departement: departement,
      profession: selectedProfession
    });
  
    try {
      const token = localStorage.getItem("token");
      const params = new URLSearchParams();
      if (selectedCountry) params.append("country", selectedCountry);
      if (selectedCity) params.append("commune", selectedCity);
      if (departement) params.append("departement", departement);
      if (selectedProfession) params.append("profession", selectedProfession);
  
      const response = await fetch(`${BASE_URL}/liste-diffuseurs?${params.toString()}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error("Erreur lors de la récupération des diffuseurs.");
      }
  
      const data = await response.json();
      console.log("Données reçues :", data); // Vérifier la structure des données
  
      // Vérifiez que `diffuseurs` est un tableau
      if (Array.isArray(data.diffuseurs)) {
        // Obtenez les coordonnées pour les diffuseurs sans lat/lng
        const updatedDiffuseurs = await Promise.all(
          data.diffuseurs.map(async (diffuseur) => {
            if (!diffuseur.lat || !diffuseur.lng) {
              const coords = await getCoordinatesFromAddress(diffuseur.commune);
              if (coords) {
                return { ...diffuseur, lat: coords.lat, lng: coords.lng };
              }
            }
            return diffuseur; // Retourne le diffuseur tel quel s'il a déjà des coordonnées
          })
        );
  
        setDiffuseurs(updatedDiffuseurs); // Mettre à jour les diffuseurs avec leurs coordonnées
      } else {
        setDiffuseurs([]); // Si aucune liste de diffuseurs trouvée
        setError("Aucun diffuseur trouvé.");
      }
    } catch (error) {
      console.error("Erreur lors de la recherche :", error);
      setError(error.message || "Une erreur est survenue.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCity) {
      handleSearch(); // Charger les diffuseurs lorsque la ville change
    }
  }, [selectedCity]);

  

  const handleSendMessage = async (e) => {
    e.preventDefault(); // Empêche le rechargement de la page
    if (!newMessage.trim()) {
        alert("Le contenu du message ne peut pas être vide.");
        return;
    }

    if (!selectedDiffuseur) {
        alert("Veuillez sélectionner un destinataire.");
        return;
    }

    try {
        const token = localStorage.getItem("token"); // Auth token
        if (!token) {
            alert("Vous devez être connecté pour envoyer un message.");
            return;
        }

        // const response = await fetch('http://localhost:8000/api/messages/send', {
          const response = await fetch(`${BASE_URL}/messages/send`, {

            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                receiver_id: selectedDiffuseur.id,
                content: newMessage,
            }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || "Erreur lors de l'envoi du message.");
        }

        const data = await response.json();
        setSuccessMessage(`Message envoyé avec succès à ${selectedDiffuseur.username} ${selectedDiffuseur.prenom}!`);
        setNewMessage(""); // Réinitialiser le champ message
        setIsModalOpen(false); // Fermer le modal principal
        setIsSuccessModalOpen(true); // Ouvrir le modal de succès
    } catch (error) {
        console.error("Erreur lors de l'envoi du message :", error);
        alert("Erreur lors de l'envoi du message.");
    }
};



  
  
  const handleLivreChange = (e) => {
    const livreId = parseInt(e.target.value, 10);
    const selected = livres.find((livre) => livre.id === livreId);
    setSelectedLivre(selected || null);
  };

  const getInitials = (username, prenom) => {
    const firstInitial = username ? username[0].toUpperCase() : '';
    const secondInitial = prenom ? prenom[0].toUpperCase() : '';
    return `${firstInitial}${secondInitial}`;
  };

  const loadCitiesFile = async (country) => {
    setLoadingCities(true);
    let filePath = '';

    if (country === 'France') {
      filePath = '/france.txt';
    } else if (country === 'Belgique') {
      filePath = '/belgique.txt';
    } else if (country === 'Suisse') {
      filePath = '/suisse.txt';
    } else if (country === 'Luxembourg') {  
      filePath = '/luxembourg.txt';
    }

    if (filePath) {
      try {
        const response = await fetch(filePath);
        if (!response.ok) {
          throw new Error(`Erreur lors du chargement du fichier: ${filePath}`);
        }
        const text = await response.text();
        const cityList = text.split('\n').map(city => city.trim()).filter(city => city);
        setCities(cityList);
      } catch (error) {
        console.error("Erreur lors du chargement des communes:", error);
        setCities([]);
      }
    } else {
      setCities([]);
    }
    setLoadingCities(false);
};


  useEffect(() => {
    if (selectedCountry) {
      loadCitiesFile(selectedCountry);
    }
  }, [selectedCountry]);
  
  

  return (
    <div className="bg-white min-h-screen">
      {/* Header */}
      <Header1 />

      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
         {/* Sidebar */}

             <Sidebar />

          {/* Main Content */}
          <div className="md:col-span-3">
            <h1 className="text-2xl font-bold mb-4">Trouver des professionnels</h1>

            {/* Department selection */}
            <div className="mb-4">
  <label className="block font-bold text-[#737373] mb-2">Rechercher géographiquement :</label>
  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
  {/* Sélection du pays */}
  <select
    value={selectedCountry}
    onChange={(e) => setSelectedCountry(e.target.value)}
    className="border border-gray-300 p-2 rounded-md"
  >
    <option value="">Sélectionner un pays</option>
    {countries.map((country) => (
      <option key={country} value={country}>
        {country}
      </option>
    ))}
  </select>

  {/* Filtrer par département (Affiché uniquement si le pays est "France") */}
  {selectedCountry === "France" && (
    <select
      value={departement}
      onChange={(e) => setDepartement(e.target.value)}
      className="border border-gray-300 p-2 rounded-md"
    >
      <option value="">Tous les départements</option>
      {departements.map((dep) => (
        <option key={dep.code} value={dep.code}>
          {dep.code} - {dep.name}
        </option>
      ))}
    </select>
  )}

  {/* Filtrer par ville */}
  <select
    className="border border-gray-300 p-2 rounded-md"
    value={selectedCity}
    onChange={(e) => setSelectedCity(e.target.value)}
  >
    <option value="">Sélectionner une commune</option>
    {cities.map((city) => (
      <option key={city} value={city}>
        {city}
      </option>
    ))}
  </select>

  {/* Filtrer par profession */}
  <select
    value={selectedProfession}
    onChange={(e) => setSelectedProfession(e.target.value)}
    className="border border-gray-300 p-2 rounded-md"
  >
    <option value="">Toutes les professions</option>
    {[
      "DIFFUSEUR",
      "AUTEUR-DIFFUSEUR",
      "GRAPHISTES",
      "CORRECTEURS",
      "ILLUSTRATEURS",
      "SCENARISTES",
      "COLORISTES",
      "TRADUCTEURS",
      "CHARGÉS D'ÉDITION",
      "CHARGÉS DE RELATION PRESSE",
      "BÊTA-LECTEURS",
    ].map((profession) => (
      <option key={profession} value={profession}>
        {profession}
      </option>
    ))}
  </select>

  {/* Bouton Rechercher */}
  <button
    onClick={handleSearch}
    className={`ml-4 px-4 py-2 rounded-md ${
      loading ? "bg-gray-500 cursor-not-allowed" : "bg-green-500 text-white hover:bg-green-600"
    }`}
    disabled={loading}
  >
    {loading ? "Recherche en cours..." : "Rechercher"}
  </button>
</div>

</div>

            {/* Google Map */}
            <LoadScript googleMapsApiKey="AIzaSyBbGmpRZdudt9VOiFSVJRTGEKjV-XG2h6o">
              <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={6}>
                {diffuseurs.map((diffuseur) => (
                  <Marker
                    key={diffuseur.id}
                    position={{ lat: parseFloat(diffuseur.lat), lng: parseFloat(diffuseur.lng) }}
                    onClick={() => setSelectedDiffuseur(diffuseur)}
                  />
                ))}

                {selectedDiffuseur && (
                  <InfoWindow
                    position={{
                      lat: parseFloat(selectedDiffuseur.lat),
                      lng: parseFloat(selectedDiffuseur.lng),
                    }}
                    onCloseClick={() => setSelectedDiffuseur(null)}
                  >
                    <div>
                      <h2>{selectedDiffuseur.username}</h2>
                      <p><strong>Prénom:</strong> {selectedDiffuseur.prenom}</p>
                      <p><strong>Commune:</strong> {selectedDiffuseur.commune}</p>
                      <p><strong>Téléphone:</strong> {selectedDiffuseur.telephone}</p>
                      <p><strong>Date de naissance:</strong> {selectedDiffuseur.date_naissance}</p>
                    </div>
                  </InfoWindow>
                )}
              </GoogleMap>
            </LoadScript>

            {/* Diffuseurs list */}
            {/* <h2 className="text-xl font-bold mt-8">Liste des Diffuseurs</h2> */}
            {/* {diffuseurs.length > 0 ? (
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
    {diffuseurs.map((diffuseur) => (
      <div key={diffuseur.id} className="border rounded-lg shadow-lg p-4 flex items-center">
        {diffuseur.photo_profil ? (
          <img
            src={`https://editions7.com/admin/public/storage/${diffuseur.photo_profil}`}
            alt={diffuseur.username}
            className="w-24 h-24 rounded-full mr-4"
          />
        ) : (
          <div
            className="w-24 h-24 rounded-full mr-4 flex items-center justify-center bg-gray-300 text-white font-bold"
            style={{ fontSize: "1.5rem" }}
          >
            {getInitials(diffuseur.username, diffuseur.prenom)}
          </div>
        )}
        <div>
          <h3 className="text-lg font-semibold mb-1">
            {diffuseur.username} {diffuseur.prenom} ({diffuseur.role})
          </h3>
          <p className="text-gray-600 mb-2">
            <strong>Né le:</strong> {diffuseur.date_naissance}
          </p>
          <p className="text-gray-600 mb-1"><strong>Commune:</strong> {diffuseur.commune}</p>
          <p className="text-gray-600 mb-1"><strong>Téléphone:</strong> {diffuseur.telephone}</p>

          {selectedProfession === "DIFFUSEUR" || selectedProfession === "AUTEUR-DIFFUSEUR" ? (
            <button
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
              onClick={() => {
                setSelectedDiffuseur(diffuseur);
                handleModalOpen();
              }}
            >
              Soumettre le Livre
            </button>
          ) : (
            <button
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
              onClick={() => {
                setSelectedDiffuseur(diffuseur);
                setIsModalOpen(true);
              }}
            >
              Contacter
            </button>
          )}
        </div>
      </div>
    ))}
  </div>
) : (
  <p className="mt-4">{loading ? "Chargement..." : "Aucun professionnel trouvé."}</p>
)} */}

{diffuseurs.length > 0 ? (
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
    {diffuseurs.map((diffuseur) => (
      <div key={diffuseur.id} className="border rounded-lg shadow-lg p-4 flex flex-col justify-between h-full">
        {/* Affichage de la photo de profil ou des initiales */}
        <div className="flex items-center">
          {diffuseur.photo_profil ? (
            <img
              src={`https://editions7.com/admin/public/storage/${diffuseur.photo_profil}`}
              alt={diffuseur.username}
              className="w-24 h-24 rounded-full mr-4"
            />
          ) : (
            <div
              className="w-24 h-24 rounded-full mr-4 flex items-center justify-center bg-gray-300 text-white font-bold"
              style={{ fontSize: "1.5rem" }}
            >
              {getInitials(diffuseur.username, diffuseur.prenom)}
            </div>
          )}

          {/* Informations de l'utilisateur */}
          <div>
            <h3 className="text-lg font-semibold mb-1">
              {diffuseur.username} {diffuseur.prenom} ({diffuseur.role})
            </h3>
            <p className="text-gray-600"><strong>Né le:</strong> {diffuseur.date_naissance}</p>
            <p className="text-gray-600"><strong>Commune:</strong> {diffuseur.commune}</p>
            <p className="text-gray-600"><strong>Téléphone:</strong> {diffuseur.telephone}</p>
          </div>
        </div>

        {/* Boutons alignés sur la même ligne */}
        <div className="flex justify-between mt-4">
          {diffuseur.role === "DIFFUSEUR" || diffuseur.role === "AUTEUR-DIFFUSEUR" ? (
            <button
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 w-full"
              onClick={() => {
                setSelectedDiffuseur(diffuseur);
                setSelectedModalType("submit"); // Définit le type de modal
              }}
            >
              📚 Soumettre le Livre
            </button>
          ) : (
            <button
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 w-full"
              onClick={() => {
                setSelectedDiffuseur(diffuseur);
                setSelectedModalType("contact"); // Définit le type de modal
              }}
            >
              📩 Contacter
            </button>
          )}
        </div>
      </div>
    ))}
  </div>
) : (
  <p className="mt-4">{loading ? "Chargement..." : "Aucun professionnel trouvé."}</p>
)}


{/* Modal pour Soumettre le Livre */}
{/* Modal pour Soumettre le Livre */}
{selectedDiffuseur && selectedModalType === "submit" && (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md max-h-[80vh] overflow-y-auto">
      <h2 className="text-xl font-bold mb-4">Partager votre fiche de livre</h2>
      <form onSubmit={handleSubmit}>
        {/* Sélection du projet */}
        <div className="mb-4">
          <label className="block font-bold mb-2">Choisir un projet :</label>
          <select
            onChange={handleLivreChange}
            className="w-full border p-2 rounded-md"
            required
          >
            <option value="">Sélectionnez un projet</option>
            {livres.map((livre) => (
              <option key={livre.id} value={livre.id}>
                {livre.title}
              </option>
            ))}
          </select>
        </div>

        {/* Couverture du livre */}
        <div className="mb-4">
          <label className="block font-bold mb-2">Couverture du livre :</label>
          {selectedLivre && selectedLivre.cover_first ? (
            <img 
              src={selectedLivre.cover_first.startsWith("https://editions7.com/admin/public/storage/") 
                ? selectedLivre.cover_first 
                : `https://editions7.com/admin/public/storage/${selectedLivre.cover_first}`
              } 
              alt="Couverture" 
              className="w-32 h-32 object-cover mx-auto" 
            />
          ) : (
            <p>Aucune couverture disponible.</p>
          )}
        </div>

        {/* Champs pour Pitch, Résumé, Quantité */}
        <div className="mb-4">
          <label className="block font-bold mb-2">Pitch de vente :</label>
          <textarea name="pitch" className="w-full border p-2 rounded-md" rows="2" required></textarea>
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2">Résumé :</label>
          <textarea
            name="resume"
            className="w-full border p-2 rounded-md"
            rows="2"
            required
          >
            {selectedLivre?.resume || ""}
          </textarea>
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2">Quantité :</label>
          <textarea
            name="quantite_envoyee"
            className="w-full border p-2 rounded-md"
            rows="2"
            required
          ></textarea>
        </div>

        {/* Email */}
        <div className="mb-4">
          <label className="block font-bold mb-2">Email du diffuseur :</label>
          <input
            type="email"
            className="w-full border p-2 rounded-md"
            value={selectedDiffuseur?.email || ""}
            readOnly
          />
        </div>

        {/* Boutons */}
        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
          >
            Envoyer
          </button>
          <button
            type="button"
            onClick={handleModalClose}
            className="ml-4 bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
          >
            Annuler
          </button>
        </div>
      </form>
    </div>
  </div>
)}


{/* Modal pour Contacter */}
{selectedDiffuseur && selectedModalType === "contact" && (

  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
      <h2 className="text-xl font-bold mb-4">Envoyer un message</h2>
      <form onSubmit={handleSendMessage}>
        <div className="mb-4">
          <label className="block font-bold mb-2">E-mail du destinataire :</label>
          <input
            type="email"
            value={selectedDiffuseur?.email || ""}
            readOnly
            className="w-full p-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-2">Message :</label>
          <textarea
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className="w-full p-2 border rounded-md"
            rows="4"
            placeholder="Écrivez votre message ici..."
          ></textarea>
        </div>
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={handleModalClose}
            className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
          >
            Annuler
          </button>
          <button
            type="submit"
            className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-500"
          >
            Envoyer
          </button>
        </div>
      </form>
    </div>
  </div>
)}



{isSuccessModalOpen && (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-bold mb-4 text-green-500">Succès</h2>
            <p className="text-gray-700 mb-4">{successMessage}</p>
            <div className="flex justify-end">
                <button
                    onClick={() => setIsSuccessModalOpen(false)}
                    className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                >
                    Fermer
                </button>
            </div>
        </div>
    </div>
)}

{isLivreEnvoyeModalOpen && (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md text-center">
      <h2 className="text-xl font-bold mb-4 text-green-500">✅ Livre envoyé avec succès !</h2>
      <p className="text-gray-700 mb-4">Votre livre a bien été soumis .</p>
      <button
        onClick={() => setIsLivreEnvoyeModalOpen(false)}
        className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
      >
        OK
      </button>
    </div>
  </div>
)}





            
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrouverDesDiffuseurs;



