import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Header1 from "./diffuseurs/Header1";
import BASE_URL from "../key";
import SidebarAuteur from "../components/sidebarAuteur";
import SidebarAuteurdiff from "../components/sidbareauteurdiff";

// const stripePromise = loadStripe("pk_test_51OAaGgFC5nnuMcZVv5nnEc5wLlTVUl17HDRtP1u0TqED8hCfXldG24pKBxOBiA00kpK0hWMQDQpaKyT5rrWjYHwG00YtG4cVHs");
const stripePromise = loadStripe("pk_live_51OAaGgFC5nnuMcZVBSWutnRASba3jzLxt52lYVmGstVtkexJ1nIokT4kTCQMxowDG6bdP82Y9jrmHbn3Z4GM6rtE00y2IQwjvo");

const PaymentForm = ({ devis, updateDevisStatus }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [error, setError] = useState("");

  const handlePayment = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setPaymentLoading(true);
    setError("");

    try {
      const response = await fetch(`${BASE_URL}/create-payments-intents`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ devis_id: devis.id }),
      });

      if (!response.ok) {
        throw new Error("Failed to create payment intent");
      }

      const { clientSecret } = await response.json();

      if (!clientSecret) {
        throw new Error("Missing client secret");
      }

      const { error: stripeError } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: { name: "Customer Name" },
        },
      });

      if (stripeError) {
        throw new Error(stripeError.message);
      }

      const paymentResponse = await fetch(`${BASE_URL}/update-devis-payment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ devis_id: devis.id, payment_intent_id: clientSecret }),
      });

      if (!paymentResponse.ok) {
        throw new Error("Failed to update payment status");
      }

      alert("Paiement réussi !");
      updateDevisStatus("PAYÉ"); // Met à jour le statut du devis
    } catch (err) {
      setError(err.message);
    } finally {
      setPaymentLoading(false);
    }
  };

  return (
    <form onSubmit={handlePayment} className="mt-6">
      <CardElement
        className="p-4 border border-gray-300 rounded"
        options={{
          style: {
            base: {
              fontSize: "16px",
              color: "#424770",
              "::placeholder": { color: "#aab7c4" },
            },
            invalid: { color: "#9e2146" },
          },
        }}
      />
      {error && <p className="text-red-500 mt-4">{error}</p>}
      <button
        type="submit"
        disabled={!stripe || paymentLoading}
        className="bg-green-500 text-white px-6 py-2 rounded hover:bg-green-600 mt-4"
      >
        {paymentLoading ? "Paiement en cours..." : "Payer"}
      </button>
    </form>
  );
};

const DevisDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [devis, setDevis] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const userRoles = ["AUTEUR", "DIFFUSEUR"];
  const [currentUserRole, setCurrentUserRole] = useState(null);

  useEffect(() => {
    const fetchDevisDetails = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${BASE_URL}/devis/${id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });

        if (!response.ok) throw new Error("Erreur lors de la récupération du devis.");

        const { success, data } = await response.json();

        if (success) setDevis(data);
        else throw new Error("Données non valides.");
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDevisDetails();
  }, [id]);

  const fetchAuthenticatedUser = async () => {
    try {
      const response = await fetch(`${BASE_URL}/user`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      if (response.ok) {
        const data = await response.json();
        setCurrentUserRole(data.role);
      } else {
        console.error("Erreur lors de la récupération des données utilisateur.");
      }
    } catch (error) {
      console.error("Erreur réseau :", error);
    }
  };

  useEffect(() => {
    fetchAuthenticatedUser();
  }, []);

  const handleReject = async () => {
    try {
        const response = await fetch(`${BASE_URL}/reject-devis/${devis.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });

        if (!response.ok) {
            throw new Error("Erreur lors de la mise à jour du statut.");
        }

        setDevis((prevDevis) => ({ ...prevDevis, status: "REJETÉ" }));
        alert("Le devis a été rejeté.");
    } catch (error) {
        console.error("Erreur lors de la mise à jour du statut :", error);
    }
};


  if (loading) return <div>Chargement...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <Elements stripe={stripePromise}>
      <div className="bg-gray-100 min-h-screen">
        <Header1 />
        <div className="container mx-auto py-10 px-4">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
            {currentUserRole === "AUTEUR" && <SidebarAuteur />}
            {currentUserRole === "AUTEUR-DIFFUSEUR" && <SidebarAuteurdiff userRoles={userRoles} />}
            <div className="md:col-span-3">
              <div className="bg-white shadow-lg rounded-lg p-8 border border-gray-200">
                <h1 className="text-3xl font-bold text-gray-800 mb-4">
                  {devis.livre_title || "Titre non spécifié"}
                </h1>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <p className="text-gray-600 mb-6">
                      <span className="font-semibold text-gray-800">Titre du Livre :</span>{" "}
                      {devis.livre_title || "Aucun titre disponible."}
                    </p>
                  </div>
                  <div>
                    <p className="text-gray-600 mb-6">
                      <span className="font-semibold text-gray-800">Statut :</span>{" "}
                      <span className="text-gray-500">{devis.status || "Non spécifié"}</span>
                    </p>
                    <p className="text-gray-600 mb-6">
                      <span className="font-semibold text-gray-800">Prix :</span>{" "}
                      <span className="text-gray-500">{devis.prix || "Non spécifié"}</span>
                    </p>
                  </div>
                </div>

                {devis.status !== "PAYÉ" && (
                  <>
                    <PaymentForm devis={devis} updateDevisStatus={(status) => setDevis((prev) => ({ ...prev, status }))} />
                    <button onClick={handleReject} className="bg-red-500 text-white px-6 py-2 rounded hover:bg-red-600 mt-4">
                      Non Payé (Rejeter)
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Elements>
  );
};

export default DevisDetails;
